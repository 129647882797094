import React from 'react';

import { useVideos } from '../providers/VideoContext';
import VideoCarousel from './VideoCarousel';
import FeaturedVideo from './FeaturedVideo';
import { Language, Video } from '../domain/types';
import LanguageSelector from './LanguageSelector';
import AuthModal from './AuthModal';
import { useTranslate } from '../hooks/useTranslate';

const VideoScreen: React.FC = () => {

  const { data, loading, login, isAuthenticated, setIsAuthenticated, register, accessCodes, email } = useVideos();

  const params = new URLSearchParams(window.location.search);

  const pk = params.get('video');
  const code = params.get('code');
  const lang = params.get('language');

  const urlLang = window.location.pathname.split('/')[1];

  const logo = 'https://www.alamirefoundation.org/static/images/logo_alpha.png';

  const determineLanguage = () => {
    if (lang && Object.keys(Language).includes(lang)) {
      return lang as Language;
    } else if (urlLang && Object.keys(Language).includes(urlLang)) {
      return urlLang as Language;
    }
    return Language.en;
  };


  const [featuredVid, setFeaturedVid] = React.useState(data?.featured_video);
  const [hasGerman, setHasGerman] = React.useState(false);
  const [language, setLanguage] = React.useState<Language>(determineLanguage());
  const [ready, setReady] = React.useState(false);

  const [removeOverlay, setRemoveOverlay] = React.useState(false);

  const { updateLanguage } = useTranslate();

  React.useEffect(() => {
    updateLanguage(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const onSetLanguage = (lang: Language) => {

    if (lang === Language.de) {
      console.log('DE');
    }

    setLanguage(lang);
  };

  console.log('HAS GERMAN');
  console.log(hasGerman);

  React.useEffect(() => {
    if (!featuredVid && data) {

      if (pk) {
        const video = data.docu_series
          .map((series) => series.videos)
          .flat()
          .find((vid) => vid.video.pk.toString() === pk);

        if (video) {

          console.log('VIDEO', video.video);

          setFeaturedVid(video.video);
          setHasGerman(!!video.video.url_de);
        }
      } else {
        setFeaturedVid(data.featured_video);
        setHasGerman(!!data.featured_video.url_de);
      }
      setReady(true);

      setTimeout(() => {
        setRemoveOverlay(true);
      }, 2000);

    } else {
      setReady(false);
      setRemoveOverlay(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, featuredVid]);

  const onSetFeatured = (video: Video) => {
    console.log('ON UPDATE FEATURED VIDEO', video);
    setFeaturedVid(video);
    setHasGerman(!!video.url_de);
  };

  React.useEffect(() => {
    if (!isAuthenticated && code && code === data?.code) {
      console.log('code approved');
      setIsAuthenticated(true);
    } else if (!isAuthenticated && code && Object.keys(accessCodes).includes(code)) {
      const expired = accessCodes[code];

      if (!expired) {
        setIsAuthenticated(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, code, isAuthenticated]);

  const onRegister = (address: string, firstName: string, lastName: string, country: string, hasEn: boolean, hasDutch: boolean) => {
    register(address, firstName, lastName, country, hasEn, hasDutch, language);
  }

  if (loading || !data || !featuredVid) {
    return (
      <div style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent:'flex-start',
        alignItems: 'flex-start',
        paddingTop: 20,
        paddingLeft: 20,
      }}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img style={{width: 100, height: 100, marginRight: 20}} src={logo} alt="Alamire logo" />
          <span style={{color: 'white', fontSize: 24}}>ALAMIRE.TV</span>
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent:'flex-start',
        alignItems: 'flex-start',
      }}>
        <div style={{display: 'flex', alignItems: 'center', paddingTop: 30, paddingLeft: 30}}>
          <img style={{width: 100, height: 100, marginRight: 20}} src={logo} alt="Alamire logo" />
          <span style={{color: 'white', fontSize: 24}}>ALAMIRE.TV</span>
        </div>
        <AuthModal
          login={login}
          register={onRegister}
          language={language}
        />
      </div>
    );
  }

  return (
    <div style={{position: 'relative'}}>
      <FeaturedVideo video={featuredVid} lang={language} email={email ?? ''} />
      <LanguageSelector currentLang={language} setCurrentLang={onSetLanguage} hasGerman={hasGerman} />
      {data.docu_series.map((series) => {
        return (
          <VideoCarousel
            title={language === 'en' ? series.title_en : series.title_nl}
            videos={series.videos}
            key={series.title_en}
            setFeatured={(video) => onSetFeatured(video)}
          />
        );
      })}
      <div style={{
        position: 'absolute',
        top: 0,
        width: '100vw',
        height: '100vh',
        display: removeOverlay ? 'none' : 'flex',
        justifyContent:'flex-start',
        alignItems: 'flex-start',
        transition: 'opacity 2s ease',
        backgroundColor: 'black',
        opacity: ready ? 1 : 0,
        paddingTop: 20,
        paddingLeft: 20,
      }}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img style={{width: 100, height: 100, marginRight: 20}} src={logo} alt="Alamire logo" />
          <span style={{color: 'white', fontSize: 24}}>ALAMIRE.TV</span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(VideoScreen);