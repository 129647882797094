import React from 'react';

import AliceCarousel, { EventObject } from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import { Video } from '../domain/types';

const breakPoints = {
  0: {
    items: 1,
    itemsFit: 'contain'
  },
  640: {
    items: 2,
    itemsFit: 'contain'
  },
  900: {
    items: 3,
    itemsFit: 'contain'
  },
  1200: {
    items: 4,
    itemsFit: 'contain'
  },
  1440: {
    items: 5,
    itemsFit: 'contain'
  },
}

interface Props {
  title: string;
  videos: {video: Video}[];
  setFeatured: (video: Video) => void;
}

interface PrevNextButtonProps {
  isPrev?: boolean;
  setHoveredControl: (control: 'prev' | 'next' | null) => void;
  hoveredControl: 'prev' | 'next' | null;
  onNav: (isPrev?: boolean) => void;
  totalItems: number;
  currentIndex: number;
  numVisible: number;
}

const PrevNextButton: React.FC<PrevNextButtonProps> = ({
  isPrev,
  setHoveredControl,
  hoveredControl,
  onNav,
  totalItems,
  currentIndex,
  numVisible
}) => {

  const isActive = isPrev ? hoveredControl === 'prev' : hoveredControl === 'next';

  if (totalItems <= numVisible) {
    return null;
  } else if (isPrev && currentIndex === 0) {
    return null;
  } else if (!isPrev && currentIndex > totalItems - numVisible - 1) {
    return null;
  }

  return (
    <div 
      onClick={() => onNav(isPrev)}
      onMouseEnter={() => setHoveredControl(isPrev ? 'prev' : 'next')}
      onMouseLeave={() => setHoveredControl(null)}
      style={{
        position: 'absolute',
        color: '#DB2600',
        top: 50,
        left: isPrev ? 0 : undefined,
        right: isPrev ? undefined : 0,
        zIndex: 10000,
        width: 60,
        height: 60,
        borderRadius: 30,
        backgroundColor: isActive ? 'white' : '#FFFFFF33',
        transition: 'background-color 0.5s ease',
        fontSize: 60,
        lineHeight: '50px',
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      {isPrev ? (
      <span>
        &#8249;
      </span>
      ) : (
      <span>
        &#8250;
      </span>
      )}
    </div>
  );
};

const VideoCarousel: React.FC<Props> = ({ title, videos, setFeatured }) => {

  const visibleVideos = React.useMemo(() => {
    return videos.filter(vid => {
      if (!vid.video.available_date_time) return true;
      const now = new Date();
      const date = new Date(vid.video.available_date_time);
      return now > date;
    });
  }, [videos]);

  const ref = React.useRef<AliceCarousel>(null);

  const [activeIndex, setActiveIndex] = React.useState<number>();
  const [numVisible, setNumVisible] = React.useState<number>();

  const [hoveredControl, setHoveredControl] = React.useState<'prev' | 'next' | null>(null);

  const handleDragStart = React.useCallback((e: any) => e.preventDefault(), []);

  const onNext = React.useCallback(() => {
    ref.current?.slideNext();
  }, []);

  const onPrev = React.useCallback(() => {
    ref.current?.slidePrev();
  }, []);

  const onClick = React.useCallback((video: Video) => {
    setFeatured(video);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdate = React.useCallback((event: EventObject) => {
    setNumVisible(event.itemsInSlide);
    setActiveIndex(event.item);
  }, []);

  const items = React.useMemo(() => visibleVideos.map((vid) => {
    return (
      <div
        key={vid.video.pk}
        style={{paddingLeft: 10, paddingRight: 10, overflow: 'hidden'}}
        onDragStart={handleDragStart}
        onClick={() => onClick(vid.video)}
      >
        <img
          className='video-carousel-img'
          style={{objectFit: 'contain', width: '100%', cursor: 'pointer', transition: 'transform 0.5s ease'}}
          src={vid.video.placeholder_image}
          alt={`${vid.video.title_en} placeholder`}
        />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [visibleVideos.length]);

  return (
    <>
    <div style={{position: 'relative', marginLeft: 50, marginRight: 50, zIndex: 10}}>
      <h3 style={{marginLeft: 10, color: '#a6361f', fontSize: '1.75rem', fontWeight: 700, marginBottom: 8, marginTop: 16 }}>{title}</h3>
      <div>
        <AliceCarousel
          ref={ref}
          mouseTracking
          items={items}
          renderDotsItem={() => null}
          responsive={breakPoints}
          onInitialized={onUpdate}
          onSlideChanged={onUpdate}
          renderNextButton={() => (
            <PrevNextButton
              setHoveredControl={setHoveredControl}
              hoveredControl={hoveredControl}
              onNav={onNext}
              totalItems={visibleVideos.length}
              currentIndex={activeIndex ?? 0}
              numVisible={numVisible ?? 0}
            />            
          )}
          renderPrevButton={() => (
            <PrevNextButton
              isPrev
              setHoveredControl={setHoveredControl}
              hoveredControl={hoveredControl}
              onNav={onPrev}
              totalItems={visibleVideos.length}
              currentIndex={activeIndex ?? 0}
              numVisible={numVisible ?? 0}
            />
          )}
        />
      </div>
    </div>
    </>
  );
};

export default React.memo(VideoCarousel);